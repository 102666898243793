import * as Sentry from '@sentry/remix';

/**
 * Event name format: "<noun> <past-tense action>"
 */
type EventName = 'Background Check Ordered' | 'Membership Purchased';

export function trackEvent(
  eventName: EventName,
  payload?: Record<string, any>
) {
  try {
    // TODO: Look into Sentry breadcrumbs
    window.heap?.track(eventName, payload);
  } catch (err) {
    Sentry.captureException(err, {
      extra: { eventName, payload }
    });
  }
}

export function identifyUser({
  email,
  id,
  firstName,
  lastName,
  organizationName
}: {
  email: string;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  organizationName?: string | null;
}) {
  tryTo(() => {
    Sentry.setUser({ id, email });
  });
  tryTo(() => {
    window.heap?.identify(id);
    window.heap?.addUserProperties({ id, email });
  });
  tryTo(() => {
    // Beacon will capture the literal string "undefined", so we have to be very specific
    const identity: Record<string, string> = {
      email
    };
    if (firstName && lastName) {
      identity.name = `${firstName} ${lastName}`;
    }
    if (organizationName) {
      identity.company = organizationName;
    }

    window.Beacon?.('identify', identity);
  });
}

export function resetUserIdentity() {
  tryTo(() => {
    Sentry.setUser(null);
  });
  tryTo(() => {
    window.heap?.resetIdentity();
  });
  tryTo(() => {
    window.Beacon?.('logout');
  });
}

export function trackPageView() {
  tryTo(() => {
    window.Beacon?.('event', {
      type: 'page-viewed',
      url: document.location.href,
      title: document.title
    });
  });
}

function tryTo(fn: () => void) {
  try {
    fn();
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.error(err);
    }
    Sentry.captureException(err);
  }
}
